import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import {
  Alert,
  Col,
  Row,
  Accordion,
} from "react-bootstrap";
import * as Yup from "yup";
import { setIn } from "formik";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { getBySlug, update } from "@services/hospitalizationcrud";
import { MedicDataTreating } from "@pages/subPages/MedicTreatingData/MedicDataTreating";
import { InsuranceData } from "@pages/subPages/InsuranceData/InsuranceData";
import { Opinion } from "@pages/subPages/Dictum/Dictum";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";

const AlwaysWithYouDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [totalLetters, setTotalLetters] = useState(0.0);
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  let history = useHistory();

  const handleSave = async function() {
    let dataForms = {};

    for (let pair of new FormData(
      document.getElementById("formu2")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu3")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    const validationSchema = Yup.object().shape({
      assuredFlastname: Yup.string().required(
        "El campo apellido paterno asegurado es requerido"
      ),
      assuredMlastname: Yup.string().required(
        "El campo apellido materno asegurado es requerido"
      ),
      assuredName: Yup.string().required(
        "El campo nombre asegurado es requerido"
      ),
      assuredEmail: Yup.string()
        .required("El campo email asegurado es requerido")
        .email("El campo email asegurado parece no ser un valido"),
      incomeDatetime: Yup.string().required(
        "El campo fecha de ingreso es requerido"
      ),
      hospitalIden: Yup.string().required(
        "El campo folio hospital es requerido"
      ),
      diagnosis: Yup.string().required(
        "El campo DIAGNÓSTICO presuntivo es requerido"
      ),
      familiarEmail: Yup.string().email(
        "El campo email familiar no parece ser un email"
      ),
    });

    try {
      await validationSchema.validate(dataForms, { abortEarly: false });

      dataForms = { ...caseRecord, ...dataForms };

      //dataForms["caseStatusId"] = 2;
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      dataForms['caseStatusId'] = null;
      update(dataForms, caseRecord.id).then((response) => {
        setErrors({});
        setHasError(false);
        history.push("/24_7_contigo");
      });
    } catch (err) {
      const errorsCatch = err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      setErrors(errorsCatch);
      setHasError(true);
    }
  };

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => setCaseRecord(response.data));

    subheader.setTitle("24/7 Contigo");
  }, [slug]);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        24/7 Contigo
      </Alert>

      <CaseHeader caseRecord={caseRecord} requiresTabulationSlot={<></>} />

      <Alert show={hasError} variant="danger" style={{ fontSize: "14px" }}>
        {Object.entries(errors).map((error, idx) => (
          <p key={idx}>{error[1]}</p>
        ))}
      </Alert>

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <HospitalData
            icon="fa-search"
            evenKey="0"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InssuredData
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="2"
            data={caseRecord}
            disabledAll={false}
            bindSubmitForm={() => {}}
          />
          <FamilyData
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            bindSubmitForm={() => {}}
            required={false}
          />
          <MedicDataTreating
            icon="fa-search"
            eventKey="4"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InsuranceData
            icon="fa-search"
            eventKey="6"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <Opinion
            icon="fa-search"
            eventKey="7"
            data={caseRecord}
            disabledAll={true}
            totalLetters={totalLetters}
            bindSubmitForm={() => {}}
          />
          <MedicalFees
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="10"
            data={caseRecord}
            hideBtnInsurance={false}
            hideFormCreate={true}
            showForm={true}
            {...props}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="8"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <Documentation
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="9"
            data={caseRecord}
            disabledAll={false}
            {...props}
          />
        </Accordion>
        <Accordion defaultActiveKey="10" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="10"
            data={caseRecord}
            disabledAll={false}
            hideButton={false}
            eventStage="24/7 Contigo"
            {...props} />
        </Accordion>
      </BlockCaseContext.Provider>

      <Row>
        <Col>
          <Button className="mt-5" variant="success" onClick={handleSave}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AlwaysWithYouDetails;
