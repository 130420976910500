import React, { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  Spinner,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
import { getAllReviewCuts, saveReviewCuts } from "@services/reviewCutsService";
import { GetUser } from "@metronic/_helpers";
import { ListReviewCuts } from "./ReviewCuts/ListReviewCuts";
import { RegistrationCaseType } from '@helpers/constants';
import axios from "axios";
import moment from "moment";
import {isHospitalAtc, isNotHospitalAtc} from "@utils/checkHospitalAtc";

export const InssuredFile = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Expediente Asegurado
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentInssuredFile {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentInssuredFile = (props) => {
  const formatToCurrency = amount => "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  
  return (
    <>
      <Alert
        variant="dark"
        className="text-center"
        style={{ fontSize: "14px" }}
      >
        Expediente Asegurado
      </Alert>

      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <b>Importe Carta Autorizacion: </b> {formatToCurrency(props.totalLetters)}
                  <br />
                  <b>Carta Suficiente: </b> {props.data.enoughYesNo}
                  <br />
                  <b>Estatus Dictamen: </b> {props.data.statusDictum}
                  <br />
                  <div hidden={isHospitalAtc(props.data)}><b>Importe Total de Desvíos: </b>${props.data.totalDeviations}</div>
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <FormInssuredFile {...props} />
        </Card.Body>
      </Card>
    </>
  );
};
export const FormInssuredFile = (props) => {
  const { id } = props.data;
  const { bindSubmitForm, bindErrors } = props;
  const [typeCase, setTypeCase] = useState("");
  const [reviewCuts, setReviewCuts] = useState([]);
  const [userAuth] = useState({ ...GetUser().user });
  const [totalDeviations, setTotalDeviations] = useState(false);

  const initialValues = {
    caseType: "",
    invoiceATC: props.data.invoiceATC || "",
    typeInvoice: props.data.typeInvoice || "",
    amountLetterSufficient: props.data.amountLetterSufficient || "",
  };

  useEffect(() => {
    if (id) {
      getAllReviewCutsInit();
      setTotalDeviations(isNotHospitalAtc(props.data));
    }
      
  }, [id]);
  
  const getAllReviewCutsInit = () => {
    getAllReviewCuts(id).then((response) => {
      setReviewCuts(response.data);
    });
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    let timestamp = await axios.get('/cases/getTimestamp');
    const hospital_datehire = moment.utc(timestamp.data).format();

    if (RegistrationCaseType.includes(values.caseType) && !props.data.hospitalDateHire) {
      values.hospitalDateHire = hospital_datehire
    }

    update(values, id).then((response) =>
      console.log("Form inssured file inside saved")
    );

    if (typeCase === "Revision de corte" || typeCase === "Alta hospitalaria") {
      values.userId = userAuth.idUser;
      values.caseId = id;
      if (isHospitalAtc(props.data)) {
        await saveReviewCuts(values);
      }
      getAllReviewCutsInit();
    }
  };

  const validationSchema = Yup.object().shape({
    caseType: Yup.string()
      .required("El campo es requerido"),
    invoiceATC: Yup.string()
      .required("El campo es requerido")
      .matches(
        /^[0-9]+$/,
        'El campo "FOLIO ATC" solo permite caracteres numericos'
      ),
    typeInvoice: Yup.string()
      .required("El campo es requerido"),
    amountLetterSufficient: Yup.string()
        .required("El campo es requerido"),
  });

  const validationSchemaAlternative = Yup.object().shape({
    caseType: Yup.string()
      .required("El campo es requerido"),
  });

  const foliosAtcIsRequired = (typeCase === "Alta hospitalaria" || typeCase === "" || typeCase === "Revision de corte") && isHospitalAtc(props.data);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmitForm}
        validationSchema={foliosAtcIsRequired ? validationSchema : validationSchemaAlternative}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
          submitForm,
        }) => {
          bindSubmitForm(submitForm);
          bindErrors(errors);
          return (
            <>
              <AlertError status={status} />
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col>
                    <Form.Group controlId="amountLetterSufficient">
                      <Form.Label>Monto en carta suficiente *</Form.Label>
                      <div className={`${getInputClasses(
                          touched,
                          errors,
                          "amountLetterSufficient"
                      )}`}>
                        <Form.Check
                            custom
                            inline
                            label="SI"
                            id="amountLetterSufficientSi"
                            type="radio"
                            value="si"
                            checked={values.amountLetterSufficient === 'si'}
                            onChange={handleChange}
                            name="amountLetterSufficient"
                        />
                        <Form.Check
                            custom
                            inline
                            label="NO"
                            id="amountLetterSufficientNo"
                            type="radio"
                            value="no"
                            checked={values.amountLetterSufficient === 'no'}
                            onChange={handleChange}
                            name="amountLetterSufficient"
                        />
                      </div>
                      <ErrorMessage name="amountLetterSufficient">
                        {(msg) => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="caseTypeOther">
                      <Form.Label>Tipo de Tramite / Movimiento *</Form.Label>
                      <Form.Control
                        as="select"
                        name="caseType"
                        value={values.caseType}
                        onChange={(e) => {
                          handleChange(e);
                          props.data.caseType = e.target.value
                          setTypeCase(e.target.value);
                          if (isHospitalAtc(props.data)) {
                            setTotalDeviations(false);
                          } else {
                            setTotalDeviations(true);
                          }
                        }}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "caseType"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="Aumento de suma">Aumento de suma</option>
                        <option value="Tabulador">Tabulador</option>
                        <option value="Alta hospitalaria">Alta hospitalaria</option>
                        <option value="Seguimiento">Seguimiento</option>
                        <option value="Dictamen">Dictamen</option>
                        <option value="Reconsideración de Alta">Reconsideración de Alta</option>
                        <option value="Reconsideración de Dictamen">Reconsideración de Dictamen</option>
                        <option value="Revision de corte">Revision de corte</option>
                      </Form.Control>
                      <ErrorMessage name="caseType">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>
                <Row hidden={totalDeviations}>
                  <Col>
                    <Form.Group controlId="typeInvoice">
                      <Form.Label>Tipo de folio ATC {foliosAtcIsRequired ? "*" : ""}</Form.Label>
                      <Form.Control
                        as="select"
                        name="typeInvoice"
                        value={values.typeInvoice}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "typeInvoice"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="Alta">Alta</option>
                        <option value="Corte">Corte</option>
                      </Form.Control>
                      <ErrorMessage name="typeInvoice">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="invoiceATC">
                      <Form.Label>Folio estado de cuenta ATC {foliosAtcIsRequired ? "*" : ""}</Form.Label>
                      <Form.Control
                        type="text"
                        name="invoiceATC"
                        value={values.invoiceATC}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "invoiceATC"
                        )}`}
                      />
                      <ErrorMessage name="invoiceATC">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                {(typeCase === "Revision de corte" || typeCase === "Alta hospitalaria") && isHospitalAtc(props.data) && <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="dark"
                          size="sm"
                        />
                      )}
                      Guardar
                    </Button>
                  </Col>
                </Row>}
              </Form>
            </>
          );
        }}
      </Formik>

      {(typeCase === "Revision de corte" || typeCase === "Alta hospitalaria") && isHospitalAtc(props.data) && <ListReviewCuts reviewCuts={reviewCuts} />}
    </>
  );
};
