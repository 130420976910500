import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert, Accordion } from "react-bootstrap";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import Documentation from "@pages/subPages/Documentation/Documentation";
import { MedicDataTreating } from "@pages/subPages/MedicTreatingData/MedicDataTreating";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { InsuranceData } from "@pages/subPages/InsuranceData/InsuranceData";
import { Opinion } from "@pages/subPages/Dictum/Dictum";
import { getBySlug } from "@services/hospitalizationcrud";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";

const GeneralConsultDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [totalLetters, setTotalLetters] = useState(0.0);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  useEffect(() => {
    subheader.setTitle("Consulta General");

    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
    });
  }, [slug]);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Consulta General
      </Alert>

      <CaseHeader caseRecord={caseRecord} requiresTabulationSlot={<></>} />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <HospitalData
            icon="fa-search"
            evenKey="0"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InssuredData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <FamilyData
            icon="fa-search"
            eventKey="3"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <MedicDataTreating
            icon="fa-search"
            eventKey="4"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InsuranceData
            icon="fa-search"
            eventKey="6"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <Opinion
            icon="fa-search"
            eventKey="7"
            data={caseRecord}
            disabledAll={true}
            totalLetters={totalLetters}
            bindSubmitForm={() => {}}
          />
          <MedicalFees
            icon="fa-search"
            eventKey="10"
            data={caseRecord}
            hideBtnInsurance={false}
            hideFormCreate={true}
            disabledAll={true}
            showForm={false}
            {...props}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="8"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <Documentation
            icon="fa-search"
            eventKey="9"
            data={caseRecord}
            disabledAll={true}
            {...props}
          />
        </Accordion>
        <Accordion defaultActiveKey="10" className="mt-5">
          <Binnacle
            icon="fa-search"
            eventKey="10"
            hideButton={true}
            data={caseRecord}
            disabledAll={true}
            eventStage="Consulta General"
            comment="SE ACTUALIZA TIPO DE TRAMITE"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>
    </>
  );
};

export default GeneralConsultDetails;
