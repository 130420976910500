import React, {useEffect, useState} from "react";
import {Alert, Modal, Button, Form, Spinner} from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import { useSubheader } from "@metronic/layout";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";

import { save } from "@services/hospitalizationcrud";
import { getCasesByStatuses } from "@services/casesService";
import GeneralTable from "@components/GeneralTable";
import GeneralFilter from "@components/GeneralFilter";
import CaseContext from "@contexts/CaseContext";
import {getInputClasses} from "@utils/getInputclasses.js";
import * as Yup from "yup";
import {update} from "@services/usersCrud.js";
import swal from "sweetalert";
import {getAll as getAllHospitals} from "@services/hospitalsService.js";
import {getProgrammingSurgeries} from "../../services/programmingsService";

const HospitalizationCreate = () => {
  const subheader = useSubheader();

  const history = useHistory();
  const [casesRecord, setCasesRecord] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });
  const [showSurgeryModal, setShowSurgeryModal] = useState(false);
  const [selectedOptionSurgery, setSelectedOptionSurgery] = useState('');

  const validationSchema = Yup.object().shape({
    hospitalId: Yup.string()
        .required("El campo es requerido"),
    atentionIden: Yup.string()
        .required("El campo es requerido"),
    identificator: Yup.string()
        .required("El campo es requerido"),
    policy: Yup.string()
        .required("El campo es requerido"),
  });

  useEffect(() => {
    getAllHospitals().then( result => setHospitals(result.data));

    subheader.setTitle("Ingreso hospitalario - Nueva solicitud");
  }, []);

  const getAllCasesByStatus = (pageNumber) => {
    getCasesByStatuses([], pageNumber).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handleShowSurgeryModal = () => setShowSurgeryModal(!showSurgeryModal)

  const createCase = (includeScheduledSurgery = false, scheduledSurgery = false) => {
    let queryParams= "";

    if (includeScheduledSurgery && scheduledSurgery) {
      queryParams = `?cirugia_programada=${scheduledSurgery}&nueva=false`;
    } else if (includeScheduledSurgery && !scheduledSurgery) {
      queryParams = `?cirugia_programada=${scheduledSurgery}&nueva=true`;
    }

    console.log(queryParams, 'test')
    save({ caseStatusId: 1 })
      .then((response) => {
        history.push(`/ingreso_hospitalario_detalles/${response.data.slug}${queryParams}`);
      })
      .catch((error) => {
        // swal("Good job!", "Ocurrio algun error", "error");
      });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  const onSubmit = (values, { setStatus, setSubmitting }) => {
    setTimeout(() => {
      getProgrammingSurgeries(values).then(response => {
        const results = response.data;

        if (results.length === 0) {
          swal({
            title: '',
            text: 'Asegurado no cuenta con Programación de Cirugía',
            icon: 'warning',
            buttons: {
              cancel: "Cancel",
              confirm: {
                text: "Continuar",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
          }).then((willContinue) => {
            if (willContinue) {
              createCase(true, false);
            }
          });
        } else if (results[0]?.caseStatusId !== 1) {
          swal({
            title: 'Asegurado con Cirugía Programada',
            text: `Esta Solicitud ya se encuentra en seguimiento con el siguiente folio: ${results[0]?.caseTicket}`,
            icon: 'success',
          });
        }
        else {
          const fullName = `${results[0]?.assuredName} ${results[0]?.assuredFlastname} ${results[0]?.assuredMlastname}`;
          swal({
            title: 'Asegurado con Cirugía Programada',
            text: `Asegurado: ${fullName}\nHospital: ${results[0].hospital?.hospitalName}\nTipo de ingreso: ${results[0]?.incomeType}`,
            icon: 'success',
            buttons: {
              cancel: "Cancel",
              confirm: {
                text: "Continuar",
                value: true,
                visible: true,
                className: "",
                closeModal: true
              }
            },
          }).then((willContinue) => {
            if (willContinue) {
              history.push(`/ingreso_hospitalario_detalles/${results[0].slug}?cirugia_programada=true&nueva=false`);
            }
          });
        }
      });
      setSubmitting(false);
    }, 1000);
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Ingreso Hospitalario
      </Alert>

      <Modal show={showSurgeryModal} onHide={handleShowSurgeryModal}>
        <Modal.Header closeButton>
          <Modal.Title>¿Cirugía programada?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="d-flex align-items-center">
            <Form.Check
                type="radio"
                id="radio-yes"
                label="Sí"
                name="isScheduledSurgery"
                value="Y"
                checked={selectedOptionSurgery === 'Y'}
                onChange={(e) => setSelectedOptionSurgery(e.target.value)}
            />
            <Form.Check
                className="ml-5"
                type="radio"
                id="radio-no"
                label="No"
                name="isScheduledSurgery"
                value="N"
                checked={selectedOptionSurgery === 'N'}
                onChange={(e) => setSelectedOptionSurgery(e.target.value)}
            />
          </Form.Group>

            <Formik onSubmit={onSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    initialValues={{hospitalId: "", atentionIden: "", identificator: "", policy: "", assuredFlastname: ""}}>
              {({values, errors, touched, handleChange, handleSubmit, status, setFieldValue, isSubmitting}) => (
                  <>
                    <Form onSubmit={handleSubmit} hidden={selectedOptionSurgery === 'N' || selectedOptionSurgery === ''}>
                      <Form.Group controlId="hospitalId">
                        <Form.Label>Hospital</Form.Label>
                        <Form.Control as="select"
                                      name="hospitalId"
                                      onChange={handleChange}
                                      className={`${getInputClasses(touched, errors, "hospitalId")}`}>
                          <option value="">Selecciona una opcion...</option>
                          {hospitals?.map( (hospital, idx) => (
                              <option key={idx} value={hospital.id}>{hospital.hospitalName}</option>
                          ))}
                          <ErrorMessage name="hospitalId">
                            { msg => (
                                <Form.Control.Feedback type="invalid">
                                  {msg}
                                </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="atentionIden">
                        <Form.Label>Atención</Form.Label>
                        <Form.Control type="text"
                                      value={values.atentionIden}
                                      onChange={handleChange}
                                      maxLength={10}
                                      name="atentionIden"
                                      className={`${getInputClasses(touched, errors, "atentionIden")}`} />

                        <ErrorMessage name="atentionIden">
                          { msg => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>

                      <Form.Group controlId="identificator">
                        <Form.Label>Folio</Form.Label>
                        <Form.Control type="text"
                                      value={values.identificator}
                                      onChange={handleChange}
                                      maxLength={10}
                                      name="identificator"
                                      className={`${getInputClasses(touched, errors, "identificator")}`} />

                        <ErrorMessage name="identificator">
                          { msg => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>

                      <Form.Group controlId="policy">
                        <Form.Label>Poliza</Form.Label>
                        <Form.Control type="text"
                                      value={values.policy}
                                      onChange={handleChange}
                                      maxLength={8}
                                      name="policy"
                                      className={`${getInputClasses(touched, errors, "policy")}`} />

                        <ErrorMessage name="policy">
                          { msg => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>

                      <Form.Group controlId="assuredFlastname">
                        <Form.Label>Apellido paterno</Form.Label>
                        <Form.Control type="text"
                                      value={values.assuredFlastname}
                                      onChange={handleChange}
                                      name="assuredFlastname"
                                      className={`${getInputClasses(touched, errors, "assuredFlastname")}`} />

                        <ErrorMessage name="assuredFlastname">
                          { msg => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>

                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                        Continuar
                      </Button>
                    </Form>
                  </>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleShowSurgeryModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={ event => createCase(false, false)} hidden={selectedOptionSurgery === 'Y' || selectedOptionSurgery === ''}>
            Solicitud reporte hospitalario
          </Button>
        </Modal.Footer>
      </Modal>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          result={casesRecord}
          statuses={[]}
          hideAxaCategoryId={true}
          hidePeriodFrom={true}
          hidePeriodTo={true}
          hideInsurance={true}
          hideHospital={true}
          hideMovementType={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideComprehensiveCare={true}
          hideTypePolicy={true}
          hideAtcNumber={true}
          textButton="Consultar"
          hideButtonCreateCase={false}
          createCase={handleShowSurgeryModal}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/seguimiento_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideReloadPageButton={true}
        hideOptionsColumn={true}
        hideFlagsColumn={true}
        hideCounterColumn={true}
        hideAdmissionDateTimeColumn={true}
        hideDiagnosticColumn={true}
        hideReasonColumn={true}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hideAxaCategoryColumn={true}
        hideDaysStayColumn={true}
        hideMountsColumn={true}
        hideHospitalDateTimeHireColumn={true}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        assignationColumn=""
        reason="Tracing"
        currModule="Seguimiento"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default HospitalizationCreate;
