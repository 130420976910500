import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { MultiSelect } from "react-multi-select-component";
import { getAll } from "@services/axaService";
import { getAll as getAllDoctors } from "@services/doctorsService";
import { getAll as getAllNurses } from "@services/nursesService";
import { update } from "@services/hospitalizationcrud";
import { storeMcMovementByCase } from "@services/mcMovementsService";
import { getAllCells } from "@services/cellService";
import { getAllConcierges } from "@services/conciergeService";
import { save as saveComment } from "@services/binnaclesService";

import BlockCaseContext from "@contexts/BlockCaseContext";
import axios from "axios";
import {GetUser} from "@metronic/_helpers";
import {AxaCategoriesOthersNotVisible, AxaCategoriesVip} from "@helpers/constants";

export const MedicAssignationTraking = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Asignacion Medico de Seguimiento
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicAssignationTraking {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentMedicAssignationTraking = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Asignacion Medico de Seguimiento
      </Alert>

      <Card>
        <Card.Body>
          <MedicAssignationTrakingForm {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const MedicAssignationTrakingForm = (props) => {
  const [axas, setAxas] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [cells, setCells] = useState([]);
  const [concierges, setConcierges] = useState([]);
  const [isNotReassigned, setIsNotReassigned] = useState(true);
  const [axaCategories, setAxaCategories] = useState([]);
  const [, setShoudBlockCase] = useContext(BlockCaseContext);
  const [vip, setVip] = useState("No");
  const [categoryAxaEnabled, setCategoryAxaEnabled]= useState(true);
  const [userAuth] = useState({ ...GetUser().user });

  //autoComplete
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");

  const { id, slug } = props.data;

  useEffect(() => {
    const hideCells = ['C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'C10', 'C11', 'C12', 'C13',
      'C14', 'C15', 'C16', 'C17', 'C18', 'Celula Pendiente', 'Celula Contingencia'];

    const getCells = () => getAllCells().then(result => {
      result.data = result.data.filter(cell => !hideCells.includes(cell.cellDescription))
      setCells(result.data)
    });

    getAll().then((response) => setAxas(response.data));
    getCells();
    setAxaCategories(props.data.axaCategories?.split(',')
        .filter(category => !AxaCategoriesOthersNotVisible.includes(category))
        .map(category => ({label: category, value: category})) || []);
    getPolicyVip();

    if (props.data.policy) {
      setInput(props.data.policy);
    }
    
    // let someElementIsVip = props.data.axaCategories?.split(',')?.some(category => category === "VIP");
    // if (someElementIsVip) {
    //   getAllConcierges().then((response) => setConcierges(response.data?.filter(concierge =>concierge.category ==="VIP")));
    // }
    getAllConcierges().then((response) => setConcierges(response.data));
    getAllDoctors().then((response) => setDoctors(response.data));
    getAllNurses().then((response) => setNurses(response.data));
  }, [props.data]);

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    values.doctorFollowId = parseInt(values.doctorFollowId) || "";
    values.nurseFollowId = parseInt(values.nurseFollowId) || "";
    values.axaCategories = values.axaCategoriesArray?.filter( axaCategory => axaCategory.value !== 'VIP')
        .map( axaCategory => axaCategory.value).join(",") || "";
    values.policy = input;

    if (values.policy && (values.policyType && values.axaCategoriesArray.some(axaCategory => AxaCategoriesVip.includes(axaCategory.value) || AxaCategoriesOthersNotVisible.includes(axaCategory.value)))) {
      if (values.axaCategories) {
        values.axaCategories = values.axaCategories + "," + values.policyType;
      } else {
        values.axaCategories = values.policyType.join(",");
      }
    } else {
      values.axaCategories = values.axaCategoriesArray?.map( axaCategory => axaCategory.value).join(",") || "";
    }

    storeMcMovementByCase(slug, values).then(response => {
      update(values, id).then((response) => {
        setShoudBlockCase(false);
        console.log("Form medic assignation traking inside saved")
      });
    });

    if (props.data.comprehensiveCare !== null && props.data.comprehensiveCare !== values.comprehensiveCare) {
      await saveComment({
        event: props.eventStage,
        comment: `Se actualizó la opción de cuidado integral`,
        userId: userAuth.idUser,
        caseType: props.data.caseType,
        axaCategories: props.data.axaCategories,
        movementsMedicalOpinion: props.data.movementsMedicalOpinion,
        complexCase: props.data.complexCase,
      }, slug)
    }
  }

  const { bindSubmitForm, bindErrors } = props;

  const initialValues = {
    policyType: props.data.axaCategories?.split(',').filter( category => AxaCategoriesVip.includes(category) || AxaCategoriesOthersNotVisible.includes(category)) || '',
    policy: props.data.policy || '',
    axaCategories: props.data.axaCategories || '',
    nurseFollowId: props.data.nurseFollowId || '',
    reassignmentMotive: props.mcMovement.reassignmentMotive || '',
    doctorFollowId: props.data.doctorFollowId || '',
    cellId: props.data.cellId || '',
    concergeId: props.data.concergeId || '',
    reasonMC: props.data.reasonMC || '',
    comprehensiveCare: props.data.comprehensiveCare || '',
  }

  const validationSchema = Yup.object().shape({
    policy: Yup.string()
        .required("El campo es requerido"),
    doctorFollowId: Yup.string()
      .required("El campo es requerido"),
    reasonMC: Yup.string()
      .required("El campo es requerido"),
    cellId: Yup.string()
      .required("El campo es requerido"),
    comprehensiveCare: Yup.string()
        .required("El campo es requerido"),
  });

  const validationSchemaTypeHospitalHigh = Yup.object().shape({
    policy: Yup.string()
        .required("El campo es requerido"),
    doctorFollowId: Yup.string()
      .required("El campo es requerido"),
    reasonMC: Yup.string()
      .required("El campo es requerido"),
    cellId: Yup.string()
      .required("El campo es requerido"),
    comprehensiveCare: Yup.string()
        .required("El campo es requerido"),
    nurseFollowId: Yup.string()
      .required("El campo es requerido"),
  });
  
  const onChangeReassignCheck = (event) =>  {
    if (event.target.value === "si") setIsNotReassigned(false);
    else setIsNotReassigned(true);
  }

  const getPolicyVip = async () => {
    let data = await axios.get(`/vip_policy`);
    setSuggestions(data.data);
  }

  const SuggestionsListComponent = ({ setFieldValue }) => {
    return filteredSuggestions.length ? (
        <ul className="suggestions">
          {filteredSuggestions.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestionIndex) {
              className = "suggestion-active";
            }

            return (
                <li className={className} key={index}  onClick={ e => {
                  onClick(e, suggestion.value);
                  setFieldValue("policyType", suggestion.source);
                }}>
                  {suggestion.label}
                </li>
            );
          })}
        </ul>
    ) : (
        <div className="no-suggestions">
        <span role="img" aria-label="tear emoji">
          😪
        </span>{" "}
          <em>Sin sugerencias</em>
        </div>
    );
  }

  const onChange = (e) => {
    const userInput = e.target.value;
    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
        (suggestion) =>
            suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    setVip("No");
  }

  const onClick = (e, suggestion) => {
    setFilteredSuggestions([]);
    setInput(suggestion);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setVip("Si");
    setCategoryAxaEnabled(true);
  }

  return (
    <Formik
      initialValues={{...initialValues, axaCategoriesArray: axaCategories}}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
      validationSchema={props.data.caseType === "Alta hospitalaria" ? validationSchemaTypeHospitalHigh : validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        bindSubmitForm(submitForm);
        bindErrors(errors);
        return (
          <>
            <AlertError status={status} />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="policy">
                    <input type="hidden" value={vip} name="vip" />
                    <Form.Label>Póliza *</Form.Label>
                    <Form.Control
                        type="text"
                        name="policy"
                        value={input}
                        onChange={ e => {
                          handleChange(e)
                          onChange(e);
                          setFieldValue("policyType", "");
                          setFieldValue("axaCategoriesArray", values.axaCategoriesArray.filter(c => c.value !== 'VIP'));
                        }}
                        disabled={props.disabledAll}
                        autoComplete="off"
                        maxLength="8"
                        className={`${getInputClasses(
                            touched,
                            errors,
                            "policy"
                        )}`}
                    />
                    {showSuggestions && input && input.length >= 7 && <SuggestionsListComponent setFieldValue={setFieldValue} />}
                    <ErrorMessage name="policy">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="policyType">
                    <Form.Label>Tipo de poliza</Form.Label>
                    <Form.Control
                        as="select"
                        name="policyType"
                        value={values.policyType}
                        onChange={handleChange}
                        disabled={props.disabledAll || categoryAxaEnabled}
                        className={`${getInputClasses(
                            touched,
                            errors,
                            "policyType"
                        )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {axas?.map((axa, idx) => (
                          <option key={idx} value={axa.axaCatDescription}>
                            {axa.axaCatDescription}
                          </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="policyType">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="axaCategoriesArray">
                    <Form.Label>Categoria AXA (Mesa de Control)</Form.Label>
                    <MultiSelect
                      name="axaCategoriesArray"
                      value={values.axaCategoriesArray}
                      overrideStrings={
                        {
                          "allItemsAreSelected": "Todos los elementos fueron seleccionados.",
                          "clearSearch": "Limpiar busqueda",
                          "noOptions": "No hay opciones",
                          "search": "Buscar",
                          "selectAll": "Seleccionar Todas",
                          "selectSomeItems": "Seleccionar..."
                        }
                      }
                      onChange={(e) => {
                        setFieldValue("axaCategoriesArray", e);
                        getAllConcierges().then((response) => setConcierges(response.data));
                        getAllNurses().then((response) => setNurses(response.data));
                        getAllDoctors().then((response) => setDoctors(response.data));
                      }}
                      options={axas?.filter(axa => !AxaCategoriesOthersNotVisible.includes(axa.axaCatDescription))
                          .map( axa => ({ label: axa.axaCatDescription, value: axa.axaCatDescription})) || []}
                      labelledBy="Selecciona las categorias AXA"
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "axaCategoriesArray"
                      )}`}
                    />
                    <ErrorMessage name="axaCategoriesArray">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="comprehensiveCare">
                    <Form.Label>Cuidado Integral *</Form.Label>
                    <div className={`${getInputClasses(
                        touched,
                        errors,
                        "comprehensiveCare"
                    )}`}>
                      <Form.Check
                          custom
                          inline
                          label="SI"
                          id="comprehensiveCareSi"
                          type="radio"
                          value="si"
                          checked={values.comprehensiveCare === 'si'}
                          onChange={handleChange}
                          name="comprehensiveCare"
                      />
                      <Form.Check
                          custom
                          inline
                          label="NO"
                          id="comprehensiveCareNo"
                          type="radio"
                          value="no"
                          checked={values.comprehensiveCare === 'no'}
                          onChange={handleChange}
                          name="comprehensiveCare"
                      />
                    </div>
                    <ErrorMessage name="comprehensiveCare">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>

                  <Form.Group controlId="type_movement">
                    <Form.Label>Reasignar</Form.Label>
                    <div className="mb-3">
                      <Form.Check
                        custom
                        inline
                        label="SI"
                        id="si"
                        type="radio"
                        value="si"
                        onChange={onChangeReassignCheck}
                        name="type_movement"
                      />
                      <Form.Check
                        custom
                        inline
                        label="NO"
                        id="no"
                        type="radio"
                        value="no"
                        onChange={onChangeReassignCheck}
                        defaultChecked={true}
                        name="type_movement"
                      />
                    </div>
                    <ErrorMessage name="type_movement">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="doctorFollowIdd">
                    <Form.Label>Medico asignado *</Form.Label>
                    <Form.Control
                      as="select"
                      name="doctorFollowId"
                      value={values.doctorFollowId}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "doctorFollowId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {doctors?.map((doctor, idx) => (
                        <option key={idx} value={doctor.id}>
                          {doctor.fullName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="doctorFollowId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="reassignmentMotive">
                    <Form.Label>Motivo de Reasignacion</Form.Label>
                    <Form.Control
                      as="select"
                      name="reassignmentMotive"
                      value={values.reassignmentMotive}
                      onChange={handleChange}
                      disabled={isNotReassigned}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "reassignmentMotive"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      <option value="Termino de jornada laboral">Termino de jornada laboral</option>
                      <option value="Permiso">Permiso</option>
                      <option value="Vacaciones">Vacaciones</option>
                      <option value="Descanso">Descanso</option>
                      <option value="Caso complejo">Caso complejo</option>
                      <option value="Caso VIP">Caso VIP</option>
                      <option value="Caso correspondiente a otra célula">Caso correspondiente a otra célula</option>
                      <option value="Reconsideración">Reconsideración</option>
                    </Form.Control>
                    <ErrorMessage name="reassignmentMotive">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="nurseFollowId">
                    <Form.Label>Enfermera (o) Asignada (o) {props.data.caseType === "Alta hospitalaria" ? "*" : ""}</Form.Label>
                    <Form.Control
                      as="select"
                      name="nurseFollowId"
                      value={values.nurseFollowId || ""}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "nurseFollowId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {nurses?.map((nurse, idx) => (
                        <option key={idx} value={nurse.id}>
                          {nurse.fullName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="nurseFollowId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="reasonMC">
                    <Form.Label>Estatus *</Form.Label>
                    {props.data.caseStatusId !== 9 ? (
                      <Form.Control
                        as="select"
                        name="reasonMC"
                        value={values.reasonMC}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "reasonMC"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="Pendiente de Asignación">Pendiente de Asignación</option>
                        <option value="Asignado">Asignado</option>
                        <option value="Recibido">Recibido</option>
                        <option value="Pendiente de Información">Pendiente de Información</option>
                      </Form.Control>
                    ) : (
                      <Form.Control
                        as="select"
                        name="reasonMC"
                        value={values.reasonMC}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "reasonMC"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="Asignado">Asignado</option>
                        <option value="Rechazo Definitivo">Rechazo Definitivo</option>
                      </Form.Control>
                    )}
                    <ErrorMessage name="reasonMC">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="concergeId">
                    <Form.Label>Concierge Asignado</Form.Label>
                    <Form.Control
                      as="select"
                      name="concergeId"
                      value={values.concergeId}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "concergeId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {concierges?.map((concierge, idx) => (
                        <option key={idx} value={concierge.id}>
                          {concierge.conciergeName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="concergeId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="cellId">
                    <Form.Label>Celula *</Form.Label>
                    <Form.Control
                      as="select"
                      name="cellId"
                      value={values.cellId}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "cellId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {cells?.map((cell, idx) => (
                        <option key={idx} value={cell.id}>
                          {cell.cellDescription}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="cellId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button variant="primary" type="submit">
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
